<template>
  <div>
    <date-range-picker
      class="w-100"
      ref="picker"
      v-model="dateRange"
      :auto-apply="true"
      :ranges="false"
      @update="onChange"
    >
      <template v-slot:input="picker">
        {{ picker.startDate | formatDate("DD-MMMM-YYYY") }}
        <span v-if="picker.startDate || picker.endDate">-</span>
        {{ picker.endDate | formatDate("DD-MMMM-YYYY") }}
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,

    DatePicker,
    DateRangePicker,
  },
  props: {
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    disableType: {
      type: String,
      default: "",
    },
  },
  watch: {
    value() {
      if (this.value != null) {
        if (typeof this.value == "object" && this.value.length == 2) {
          this.dateRange.startDate = this.value[0];
          this.dateRange.endDate = this.value[1];
        }
      } else {
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
      }
    },
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      from: null,
      to: null,
    };
  },
  methods: {
    onChange() {
      this.emitInput();
    },
    emitInput() {
      this.$emit("input", [
        moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        moment(this.dateRange.endDate).format("YYYY-MM-DD"),
      ]);
    },
  },
};
</script>