<template>
  <div>
    <b-row class="year-group">
      <b-col class="pr-0" cols="6">
        <div class="date-picker">
          <date-picker
            v-model="from"
            type="year"
            format="YYYY"
            value-type="YYYY"
            :clearable="clearable"
            :disabled-date="checkDisable"
          ></date-picker>
        </div>
      </b-col>
      <b-col class="pl-0" cols="6">
        <div class="date-picker">
          <date-picker
            v-model="to"
            type="year"
            format="YYYY"
            value-type="YYYY"
            :clearable="clearable"
            :disabled-date="checkDisable"
          ></date-picker>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    BRow,
    BCol,

    DatePicker,
  },
  props: {
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    disableType: {
      type: String,
      default: "",
    },
  },
  watch: {
    value() {
      if (this.value != null) {
        if (typeof this.value == "object" && this.value.length == 2) {
          this.from = this.value[0];
          this.to = this.value[1];
        }
      } else {
        this.from = null;
        this.to = null;
      }
    },
    from(value) {
      this.emitInput(value, this.to);
    },
    to(value) {
      this.emitInput(this.from, value);
    },
  },
  data() {
    return {
      from: null,
      to: null,
    };
  },
  methods: {
    emitInput(from, to) {
      this.$emit("input", [from, to]);
    },
    checkDisable(date) {
      if (this.disableType == "before") {
        return this.disabledBeforeToday(date);
      } else if (this.disableType == "after") {
        return this.disabledAfterToday(date);
      }

      return false;
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
  },
};
</script>